<template lang="pug">
  .hcaptcha-component
    .preloader
    .captcha
      VueHcaptcha(
        :sitekey="siteKey"
        @error="onError"
        @verify="onVerify"
        @rendered="onRender")
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'

export default {
  name: 'HCaptcha',
  components: { VueHcaptcha },
  props: {
    siteKey: {
      type: String,
      default: null
    }
  },
  methods: {
    onRender () {
      this.$emit('onRender')
    },
    onVerify (response) {
      this.$emit('onVerify', response)
    },
    onError (err) {
      this.$emit('onError', err)
    }
  }
}
</script>

<style lang="scss" scoped>
  .hcaptcha-component {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 8px;

    .preloader {
      position: absolute;
      width: 20px;
      height: 20px;
      border: 2px solid;
      border-color: #E4EAEF #E4EAEF transparent #E4EAEF;
      border-radius: 50%;
      z-index: 0;
      transform-origin: center;
      animation: preloader 1s linear infinite;

      @keyframes preloader {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .captcha {
      z-index: 1;
    }
  }
</style>
