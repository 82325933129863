<template lang="pug">
  .button-channel-component(
    @click="onClick"
    @keypress.enter="onClick"
    :class="classList"
    tabindex="0")
    .channel-icon
      component(:is="channel.icon")
    .channel-name
      span {{ channel.name }}
    .icon-chevron
      IconChevron
</template>

<script>
import IconChevron from '@/components/icons/IconChevron'
import IconVK from '@/components/icons/IconVK'
import IconWhatsApp from '@/components/icons/IconWhatsApp'
import IconConfirmationCall from '@/components/icons/IconConfirmationCall'
import IconSMS from '@/components/icons/IconSMS'
import IconViber from '@/components/icons/IconViber.vue'
import IconTelegram from '@/components/icons/IconTelegram.vue'

export default {
  name: 'ButtonChannel',
  components: {
    IconChevron,
    IconVK,
    IconWhatsApp,
    IconConfirmationCall,
    IconSMS,
    IconViber,
    IconTelegram
  },
  props: {
    channel: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    classList () {
      let channel = ''

      if (['WHATSAPP', 'WEASY'].includes(this.channel.value)) {
        channel = 'channel-whatsapp'
      } else if (['VOICECODE', 'FLASHCALL'].includes(this.channel.value)) {
        channel = 'channel-voicecode'
      } else {
        channel = `channel-${this.channel.value.toLowerCase()}`
      }

      return {
        [channel]: !!channel
      }
    }
  },
  methods: {
    onClick () {
      this.$emit('click', this.channel)
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-channel-component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 14px;
    border-radius: 8px;
    box-shadow: 0 0 0 1px $color-gray-40 inset;
    cursor: pointer;

    &.channel {
      &-vk {
        &:hover {
          box-shadow: 0 0 0 1px rgba(#1A85FF, 0.8) inset;

          .icon-chevron {
            color: #1A85FF;
          }
        }
      }

      &-whatsapp {
        &:hover {
          box-shadow: 0 0 0 1px rgba(#4DC247, 0.8) inset;

          .icon-chevron {
            color: #4DC247;
          }
        }
      }

      &-voicecode {
        &:hover {
          box-shadow: 0 0 0 1px rgba(#F5B740, 0.8) inset;

          .icon-chevron {
            color: #F5B740;
          }
        }
      }

      &-sms {
        &:hover {
          box-shadow: 0 0 0 1px rgba(#14C09B, 0.8) inset;

          .icon-chevron {
            color: #14C09B;
          }
        }
      }

      &-viber {
        &:hover {
          box-shadow: 0 0 0 1px rgba(#7F4DA0, 0.8) inset;

          .icon-chevron {
            color: #7F4DA0;
          }
        }
      }

      &-telegram {
        &:hover {
          box-shadow: 0 0 0 1px rgba(#199DE6, 0.8) inset;

          .icon-chevron {
            color: #199DE6;
          }
        }
      }
    }

    & + .button-channel-component {
      margin-top: 4px;
    }

    .channel-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .channel-name {
      flex: 1;
      width: 100%;
      text-align: left;
      user-select: none;
      margin-right: 8px;

      span {
        font-family: 'Euclid Circular A', serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .icon-chevron {
      width: 16px;
      height: 16px;
      color: #BAC5CD;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
