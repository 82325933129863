import { render, staticRenderFns } from "./IconPreloader.vue?vue&type=template&id=238d83a8&scoped=true&lang=html"
import script from "./IconPreloader.vue?vue&type=script&lang=js"
export * from "./IconPreloader.vue?vue&type=script&lang=js"
import style0 from "./IconPreloader.vue?vue&type=style&index=0&id=238d83a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "238d83a8",
  null
  
)

export default component.exports