import { render, staticRenderFns } from "./confirmation-message.vue?vue&type=template&id=f7916294&scoped=true&lang=pug"
import script from "./confirmation-message.vue?vue&type=script&lang=js"
export * from "./confirmation-message.vue?vue&type=script&lang=js"
import style0 from "./confirmation-message.vue?vue&type=style&index=0&id=f7916294&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7916294",
  null
  
)

export default component.exports