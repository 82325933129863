import Vue from 'vue'
import App from './App.vue'

let vue = Vue
let idgtlWidgetMounted = false

/**
 * Подготовка виджета к монтированию в Tilda
 * @param formID {string} - ID формы, которую указал пользователь. Формат "#yourid"
 * @param mountAt {string} - ID блока в DOM куда будет монтироваться элемент. Формат "#yourid"
 * @param {Object} options {Object} - Объект с опциями
 * @param {string} options.widgetId - ID виджета
 * @param {string} options.captchaSiteKey - ключ от reCaptcha/hCaptcha
 * @param {string} options.apiKey - API-ключ от Tilda
 * @param {string | undefined} options._customBaseUrl - кастомный URL по которому будет ходить виджет (default - URL продакшен-сервера direct)
 * @param {boolean | undefined} options.windowed - включение режима вывода виджета в окне
 * @param onSuccessCallback {function | null} - callback-функция, которая выполнится при успешной верификации
 * @returns {void}
 */
export const tildaPrepare = (formID, mountAt = '', options = {}, onSuccessCallback = null) => {
  function prepareForm () {
    Vue.prototype.$tildaFormID = formID

    const tildaForm = document.querySelector(`${formID} form`) || document.querySelector(`form${formID}`)

    if (!tildaForm) {
      console.warn(`[verify-widget]: Формы с ID ${formID} не найдено`)
      return
    }

    const tildaFormButton = tildaForm.querySelector('[type="submit"]')

    const eventHandler = (event) => validateAndMountWidget(event, formID, mountAt, options, onSuccessCallback)

    function widgetTildaFormSubmitFunction () {
      tildaFormButton.removeEventListener('click', eventHandler, false)
      tildaFormButton.click()
    }

    Vue.prototype.$widgetTildaFormSubmitFunction = widgetTildaFormSubmitFunction

    tildaFormButton.addEventListener('click', eventHandler, false)
  }

  if (!formID || formID === '#') {
    console.warn('[verify-widget]: Укажите ID формы Tilda')
    return
  }

  if (!mountAt || mountAt === '#') {
    console.warn('[verify-widget]: Укажите ID элемента для монтирования виджета')
    return
  }

  function checkIsReadyCallPrepareForm () {
    if (window.tildaForm) {
      prepareForm()
    } else {
      Object.defineProperty(window, 'tildaForm', {
        set (value) {
          Object.defineProperty(this, 'tildaForm', { value, writable: true, configurable: true })

          prepareForm()
        },
        get () {
          return this._tildaForm
        },
        configurable: true
      })
    }
  }

  document.addEventListener('DOMContentLoaded', checkIsReadyCallPrepareForm)
}

/**
 * Функция валидации формы и открытия виджета по клике на кнопку Submit в форме Tilda
 * @param {Event} event
 * @param {string} formID
 * @param {string} mountAt
 * @param {Object} options
 * @param onSuccessCallback {function | null}
 * @returns {void}
 */
const validateAndMountWidget = (event, formID, mountAt, options = {}, onSuccessCallback = null) => {
  event.preventDefault()
  event.stopPropagation()

  if (idgtlWidgetMounted) {
    const widgetEl = document.querySelector('#idgtl-2fa-widget')

    if (widgetEl) {
      unmount(mountAt)
    }
  }

  let destination = ''

  const tildaForm = document.querySelector(`${formID} form`) || document.querySelector(`form${formID}`)
  const tildaFormButton = tildaForm.querySelector('[type="submit"]')
  const tildaFormPhoneInput = tildaForm.querySelector('[data-tilda-rule="phone"]')

  if (!tildaFormPhoneInput) {
    console.warn(`[verify-widget]: Не найдено поле ввода номера телефона на форме Tilda с ID ${formID}`)
    return
  }

  window.tildaForm.hideErrors(tildaFormButton.form)

  const errors = window.tildaForm.validate(tildaForm)

  if (errors.length) {
    window.tildaForm.showErrors(tildaForm, errors, { inputBoxSelector: '.t-input-group' })
  } else {
    destination = tildaFormPhoneInput.value.replace(/[)(\-\s]/gmi, '')

    const widgetOptions = {
      ...options,
      destination
    }

    mount(mountAt, widgetOptions, () => {}, onSuccessCallback)
  }
}

/**
 * Функция монтирования виджета в DOM-дерево Tilda
 * @param mountAt {string}
 * @param options {object}
 * @param checkCallback {function | null}
 * @param onSuccessCallback {function | null}
 * @returns {void}
 */
export const mount = (mountAt = '', options = {}, checkCallback = null, onSuccessCallback = null) => {
  if (!mountAt || mountAt === '#') {
    console.warn('[verify-widget]: You must provide DOM element to mount to')
    return
  }

  if (!document.querySelector(mountAt)) {
    console.warn(`[verify-widget]: There's no element with id ${mountAt} in DOM`)
    return
  }

  if (options && options instanceof Object) {
    Vue.prototype.$widgetOptions = JSON.parse(JSON.stringify(options))
  }

  Vue.prototype.$widgetCheckCallback = checkCallback
  Vue.prototype.$widgetOnSuccessCallback = onSuccessCallback

  const mountAtElement = document.querySelector(mountAt)
  const widgetContainer = document.createElement('div')
  widgetContainer.id = 'widget-main-container'
  mountAtElement.appendChild(widgetContainer)

  vue = new Vue({
    render: h => h(App)
  })

  vue.$mount('#widget-main-container')

  idgtlWidgetMounted = true
}

export const unmount = () => {
  if (vue.$children && vue.$children[0] && vue.$children[0].removeWidget) {
    vue.$children[0].removeWidget()
  }
}
