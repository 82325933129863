<template lang="pug">
  .input-otp-component(:class="classList")
    .otp-container(v-for="(input, i) in quantity" :key="i")
      input(
        @click="onClick"
        @input="onInput"
        @paste="onPaste"
        @focus.stop="updateActiveElId"
        @keydown.right="pressRight"
        @keydown.left="pressLeft"
        @keydown.up="e => e.preventDefault()"
        @keydown.down="e => e.preventDefault()"
        @keydown.delete="pressDelete"
        @keypress="onKeyPress"
        :autofocus="hasAutofocus(i)"
        :data-id="i"
        :ref="`input-${i}`"
        :disabled="disabled || loading"
        :aria-label="`Please enter OTP character ${i + 1}`"
        autocomplete="off"
        inputmode="numeric"
        :value="value[i]"
        type="tel"
        min="0")

      transition(name="fade")
        .preloader(v-if="loading")
</template>

<script>
export default {
  name: 'InputOtp',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    quantity: {
      type: [String, Number],
      default: 5
    },
    loading: Boolean,
    disabled: Boolean,
    error: Boolean,
    autofocus: Boolean
  },
  data () {
    return {
      id: 0
    }
  },
  computed: {
    classList () {
      return {
        disabled: this.disabled,
        loading: this.loading,
        error: !!this.error,
        'transition-error': !!this.error
      }
    },
    getInputsArr () {
      const inputs = []

      for (let i = 0; i < this.quantity; i++) {
        const input = this.$refs[`input-${i}`]?.[0] || null
        if (input) inputs.push(input)
      }

      return inputs
    }
  },
  methods: {
    resetCode () {
      this.getInputsArr.forEach(el => {
        el.value = ''
      })

      if (this.$refs['input-0'] && this.$refs['input-0'][0] && this.autofocus) {
        this.$nextTick(() => {
          this.$refs['input-0'][0].focus()
        })
      }

      this.$emit('input', '')
      this.$emit('onReset')
    },
    onClick (e) {
      e.target.setSelectionRange(0, 1)
      this.id = Number(e.target.dataset.id)
    },
    onPaste (e) {
      e.preventDefault()

      let clipboardData = e.clipboardData || window.clipboardData
      clipboardData = clipboardData.getData('Text')
      clipboardData = clipboardData.split('')

      clipboardData = clipboardData.map(item => {
        const char = Number(item)
        return !isNaN(char) ? char : ''
      })

      clipboardData.forEach((char, i) => {
        if (i < this.quantity) {
          if (clipboardData[i] !== undefined && String(clipboardData[i])) {
            this.$refs[`input-${i}`][0].value = ''
            this.$refs[`input-${i}`][0].value = clipboardData[i]
            this.id = i
          }
        }
      })

      this.$emit('onPaste')
      this.updateValue()
      this.getInputsArr[this.id].focus()
    },
    onKeyPress (e) {
      if (/\D/.test(e.key)) e.preventDefault()
    },
    hasAutofocus (i) {
      return !i && this.autofocus
    },
    updateActiveElId (e) {
      e.preventDefault()

      const charCode = (e.which) ? e.which : e.keyCode
      if (charCode && (charCode < 48 || charCode > 57)) {
        e.preventDefault()
        return
      }

      this.id = Number(e.target.dataset.id)
      e.target.setSelectionRange(0, 1)
    },
    async onInput (e) {
      e.target.value = e.target.value.replace(/\D/gmi, '')

      if (e.target.value === '') {
        e.preventDefault()
        this.updateValue()
        return
      }

      const quantity = Number(this.quantity)

      if (Number(e.target.dataset.id) === quantity - 1 && e.target.value.length > 1 && !e.data) {
        e.target.value = e.target.value[e.target.value.length - 1]
      }

      e.target.select()

      if (this.id < quantity - 1) {
        let el = null
        el = this.$refs[`input-${this.id + 1}`][0]
        this.id = Number(el.dataset.id)
        this.focusOnEl(el)
      }

      this.updateValue()
      await this.$nextTick()

      if (this.getInputsArr.every(el => el.value) && (!e.data || e?.data?.length > 1)) {
        let el = null
        el = this.getInputsArr[quantity - 1]
        this.id = Number(el.dataset.id)
        this.focusOnEl(el)
      }

      const lastFilledIndex = this.getInputsArr.findLastIndex(el => el.value.length)
      if (!e.target.value && lastFilledIndex !== -1) {
        this.id = lastFilledIndex + 1
        this.focusOnEl(this.getInputsArr[lastFilledIndex + 1])
      }

      this.getInputsArr.forEach(el => {
        el.value = el.value.slice(0, 1)
      })
    },
    pressLeft (e) {
      if (e.key !== 'ArrowLeft') return

      if (this.id) {
        const el = this.getInputsArr[this.id - 1]
        this.id = Number(el.dataset.id)
        this.focusOnEl(el)
      } else {
        this.focusOnEl(e.target)
      }
    },
    pressRight (e) {
      if (e.key !== 'ArrowRight') return

      if (this.id < this.quantity - 1) {
        const el = this.getInputsArr[this.id + 1]
        this.id = Number(el.dataset.id)
        this.focusOnEl(el)
      } else {
        this.focusOnEl(e.target)
      }
    },
    pressDelete (e) {
      if (e.target.value) return
      const currentElementIndex = Number(e.target.dataset.id) ?? null
      if (e.key !== 'Backspace' || currentElementIndex === null) return

      const prevElement = this.getInputsArr[currentElementIndex - 1] || null
      this.id = prevElement ? Number(prevElement.dataset.id) : 0
      if (prevElement) this.focusOnEl(prevElement)
    },
    updateValue () {
      const quantity = Number(this.quantity)
      let value = this.getInputsArr.map(el => el.value)
      value = value.join('').slice(0, quantity)

      this.$emit('input', value)

      if (value.length === this.quantity) {
        this.$emit('onComplete', value)
      }
    },
    focusOnEl (el) {
      el.focus()
      setTimeout(() => {
        el.setSelectionRange(0, 1)
        el.select()
      }, 0)
    },
    changeToNumType () {
      for (let i = 0; i < this.quantity; i++) {
        this.$refs[`input-${i}`][0].setAttribute('type', 'tel')
      }
    }
  },
  mounted () {
    for (let i = 0; i < this.quantity; i++) {
      const el = this.getInputsArr[i]
      el.addEventListener('touchstart', this.changeToNumType)
    }

    this.id = 0

    if (this.value) {
      let valueArr = this.value.split('')
      if (valueArr.length > this.quantity) {
        valueArr = valueArr.slice(0, this.quantity)
      }

      valueArr = valueArr.map(value => {
        let num = Number(value)
        if (isNaN(num)) num = ''
        return num
      })

      for (let i = 0; i < valueArr.length; i++) {
        this.getInputsArr[i].value = valueArr[i]
      }
    }
  },
  beforeDestroy () {
    this.id = 0
  }
}
</script>

<style lang="scss" scoped>
  .input-otp-component {
    display: flex;

    &.disabled .otp-container input {
      cursor: default;
      background-color: $color-gray-10;
      box-shadow: 0 0 0 1px $color-gray-30 inset;
    }

    &.error .otp-container input {
      box-shadow: 0 0 0 2px #FF665C inset;
    }

    .otp-container {
      position: relative;

      & + .otp-container {
        margin-left: 8px;
      }

      .preloader {
        @include preloader();
        left: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 4px;
      }

      input {
        display: block;
        height: 48px;
        width: 48px;
        padding: 9px 12px;
        text-align: center;
        box-shadow: 0 0 0 1px $color-gray-40 inset;
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        border: 0;
        border-radius: 4px;
        outline: none;
        background-color: $color-white;
        transition: box-shadow 0.1s ease;
        caret-color: transparent;
        cursor: pointer;
        -webkit-appearance: none;

        &:focus {
          box-shadow: 0 0 0 2px $color-light-blue-100 inset;
        }

        &:disabled {
          cursor: default;
        }

        &::selection {
          background-color: transparent;
        }
      }
    }

    @include transition-error()
  }
</style>
