<template lang="pug">
  .telegram-qr-code-section-component
    .section-title
      span Поделитесь номером {{ destination }}&nbsp;с ботом и&nbsp;нажмите &laquo;Получить код&raquo;

    .bot-name
      a.link-telegram(:href="getHref" target="_blank") @{{ botName }}

    .qr-code(ref="qrCode")

    button.button-accept(@click="$emit('onRepeat')") Получить код

    button.button-back(@click="$emit('goBack')") Назад
</template>

<script>
import { QrCodeInit } from '@/utils/qrcode'

export default {
  name: 'TelegramQrCodeSection',
  props: {
    destination: {
      type: String,
      default: ''
    },
    botName: {
      type: String,
      default: ''
    }
  },
  computed: {
    getHref () {
      return this.botName ? `https://t.me/${this.botName}` : ''
    }
  },
  methods: {
    renderQr () {
      if (!this.$refs.qrCode || !this.getHref) return

      const qr = QrCodeInit()
      qr.renderQr(this.$refs.qrCode, {
        text: this.getHref || '',
        width: 160,
        height: 160,
        colorDark: 'rgb(45, 0, 108)'
      })
    }
  },
  mounted () {
    this.renderQr()
  }
}
</script>

<style lang="scss" scoped>
  .telegram-qr-code-section-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    .section-title {
      text-align: center;
      margin-bottom: 12px;
      cursor: default;

      span {
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
      }
    }

    .bot-name {
      text-align: center;
      margin-bottom: 4px;
      cursor: default;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .qr-code {
      width: 160px;
      height: 160px;
      margin-bottom: 12px;

      ::v-deep img {
        width: 100%;
      }
    }

    .link-telegram,
    .button-accept {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      margin-bottom: 8px;
      color: #009CE5;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: #FF5449;
      }
    }

    .link-telegram {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
    }

    .button-back {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      color: $color-gray-100;
      cursor: pointer;

      &:hover {
        color: #FF5449;
      }
    }

    @media(max-width: 767px) {
      max-width: 304px;
    }
  }
</style>
