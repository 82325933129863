<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#199DE6"/>
    <path
        d="M6.38595 11.5962C10.0516 9.98361 14.7953 8.01749 15.4501 7.74536C17.1677 7.03311 17.6947 7.16961 17.432 8.74694C17.2432 9.88065 16.6987 13.6341 16.2647 15.9704C16.0072 17.3558 15.4294 17.52 14.5208 16.9206C14.0839 16.6322 11.8786 15.1738 11.4 14.8314C10.9631 14.5193 10.3605 14.1439 11.1162 13.4046C11.3851 13.1412 13.1479 11.458 14.5214 10.1478C14.7014 9.97574 14.4753 9.69311 14.2677 9.83107C12.4165 11.0587 9.84978 12.7626 9.52312 12.9846C9.02962 13.3197 8.55566 13.4734 7.70487 13.229C7.06203 13.0444 6.43407 12.8242 6.18966 12.7402C5.24845 12.417 5.47187 11.9984 6.38595 11.5962Z"
        fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'IconTelegram'
}
</script>
